<template>
    <v-container
        id="instruments"
        fluid
        tag="section"
    >
        <div class="text-center mt-10 mb-2" v-if="!packageAll.paytime || packageAll.paytime < datePay">
            <v-btn class="mr-0" color="success" :to="{name: 'PayInstruments'}">Доступ к инструментам</v-btn>
        </div>        
        <div class="block-nav">
          <p class="p-nav">
            <a @click="scrollToBusiness">Бизнес инструменты</a>
          </p>
          <p class="p-nav">
            <a @click="scrollToProducts">Продуктовые инструменты</a>
          </p>        
          <!-- <p class="p-nav">
            <a @click="scrollToLearn">Запуск и обучение</a>
          </p>   -->
        </div>
        <h2 id="learn" class="mt-10" style="text-align: center; text-transform: uppercase">Запуск и обучение</h2>
        <v-row
            justify="center"
        >
        
        <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
            <v-card
                class="mx-auto"
                max-width="374"
            >
                <v-img
                    height="200"
                    src="/img/learn/virtual.png"
                ></v-img>

                <v-card-title class="inst-title">
                    <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">
                      Виртуальный наставник <br />
                      <span style="text-transform: none; color: #c00;">(Star Mentor)</span>
                    </p>
                </v-card-title>

                <v-card-actions class="pt-0 pb-4">
                    <v-btn style="margin: auto" text rounded color="success" :to="{name: 'VirtualLearn'}">Перейти</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
            <v-card
                class="mx-auto"
                max-width="374"
            >
                <v-img
                    height="200"
                    src="/img/learn/star-sales.jpg"
                ></v-img>

                <v-card-title class="inst-title">
                    <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">
                      Большой ЛТО <br />
                      <span style="text-transform: none; color: #c00;">(Star Sales)</span>
                    </p>
                </v-card-title>

                <v-card-actions class="pt-0 pb-4">
                    <v-btn style="margin: auto" text rounded color="success" :to="{name: 'StarSalesLearn'}">Перейти</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
            <v-card
                class="mx-auto"
                max-width="374"
            >
                <v-img
                    height="200"
                    src="/img/learn/grow-team.jpg"
                ></v-img>

                <v-card-title class="inst-title">
                    <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">
                      Рост команды
                      <br />
                      <span style="text-transform: none; color: #c00;">(Star Building)</span>
                    </p>
                </v-card-title>

                <v-card-actions class="pt-0 pb-4">
                    <v-btn style="margin: auto" text rounded color="success" :to="{name: 'GrowTeamLearn'}">Перейти</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>   
        <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
              <v-card
                  class="mx-auto"
                  max-width="374"
              >
                  <v-img
                      height="200"
                      src="/img/learn/online.png"
                  ></v-img>

                  <v-card-title class="inst-title">
                      <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">
                        МАСШТАБИРОВАНИЕ ОНЛАЙН
                        <br />
                        <span style="text-transform: none; color: #c00;">(Star Network)</span>
                      </p>                      
                  </v-card-title>

                  <v-card-actions class="pt-0 pb-4">
                      <v-btn style="margin: auto" text rounded color="success" :to="{name: 'OnlineLearn'}">Перейти</v-btn>
                  </v-card-actions>
              </v-card>
          </v-col>

        <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
              <v-card
                  class="mx-auto"
                  max-width="374"
              >
                  <v-img
                      height="200"
                      src="/img/learn/zapusk-novichka.png"
                  ></v-img>

                  <v-card-title class="inst-title">
                      <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Сайт по запуску</p>
                  </v-card-title>

                  <v-card-actions class="pt-0 pb-4">
                      <v-btn style="margin: auto" text rounded color="success" :to="{name: 'ZapuskLearn'}">Перейти</v-btn>
                  </v-card-actions>
              </v-card>
          </v-col>
          <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
            <v-card
                class="mx-auto"
                max-width="374"
            >
                <v-img
                    height="200"
                    src="/img/learn/StarTrack.jpg"
                ></v-img>

                <v-card-title class="inst-title">
                    <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">
                      Материалы для StarTrack
                      <!-- <span style="text-transform: none; color: #c00;">RoboMoney</span> -->
                    </p>
                </v-card-title>

                <v-card-actions class="pt-0 pb-4">
                    <v-btn style="margin: auto" text rounded color="success" :to="{name: 'StarTrackLearn'}">Перейти</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        </v-row>

        <h2 id="business" class="mt-10" style="text-align: center; text-transform: uppercase">Бизнес инструменты</h2>
        <v-row
            justify="center"
        >
        <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
            <v-card
                class="mx-auto"
                max-width="374"
            >
                <v-img
                    height="200"
                    src="/img/learn/RoboMoney.jpg"
                ></v-img>

                <v-card-title class="inst-title">
                    <p class="mt-5" style="text-align: center; width: 100%; text-transform: none">
                      <!-- RoboMoney <br /> -->
                      <span style="text-transform: none; color: #c00;">RoboMoney</span>
                    </p>
                </v-card-title>

                <v-card-actions class="pt-0 pb-4">
                    <v-btn style="margin: auto" text rounded color="success" :to="{name: 'RoboMoneyLearn'}">Перейти</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/viz-inst.png"
                        style="border-bottom: 1px solid #cccccc"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Страница-визитка</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'VizitkaInstrum'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/start-inst.png"
                        style="border-bottom: 1px solid #cccccc"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Стартовые наборы</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'StartProducts'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/htest-inst.png"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Онлайн-тест по здоровью</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'TestInstrum'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/btest-inst.png"
                        style="border-bottom: 1px solid #cccccc"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Онлайн-тест по бизнесу</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'BizTestInstrum'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/biz-inst.png"
                        style="border-bottom: 1px solid #cccccc"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Бизнес-презентация</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'BizInstrum'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/leedbot-inst.png"
                        style="border-bottom: 1px solid #cccccc"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Лидбот по бизнесу</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'LeedbotInstrum'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <h2 id="products" class="mt-10" style="text-align: center; text-transform: uppercase">Продуктовые инструменты</h2>
        <v-row
            justify="center"
        >
        <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
              <v-card
                  class="mx-auto"
                  max-width="374"
              >
                  <v-img
                      height="200"
                      src="/img/aloe-inst.png"
                  ></v-img>

                  <v-card-title class="inst-title">
                      <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Гели Алоэ Вера</p>
                  </v-card-title>

                  <v-card-actions class="pt-0 pb-4">
                      <v-btn style="margin: auto" text rounded color="success" :to="{name: 'AloeInstrum'}">Перейти</v-btn>
                  </v-card-actions>
              </v-card>
          </v-col>
        <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/manelixir/man-instrum.jpg"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Мужской эликсир</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'MeInstrum'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
            <v-card
                class="mx-auto"
                max-width="374"
            >
                <v-img
                    height="200"
                    src="/img/learn/star-health.png"
                ></v-img>

                <v-card-title class="inst-title">
                    <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">
                      Результат по здоровью
                      <br />
                      <span style="text-transform: none; color: #c00;">(Star Health)</span>
                    </p>                      
                </v-card-title>

                <v-card-actions class="pt-0 pb-4">
                    <v-btn style="margin: auto" text rounded color="success" :to="{name: 'StarHealthLearn'}">Перейти</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
          <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
              <v-card
                  class="mx-auto"
                  max-width="374"
              >
                  <v-img
                      height="200"
                      src="/img/zg-inst.png"
                  ></v-img>

                  <v-card-title class="inst-title">
                      <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">ZeitGard</p>
                  </v-card-title>

                  <v-card-actions class="pt-0 pb-4">
                      <v-btn style="margin: auto" text rounded color="success" :to="{name: 'ZgInstrum'}">Перейти</v-btn>
                  </v-card-actions>
              </v-card>
          </v-col>       
           
          

          <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
              <v-card
                  class="mx-auto"
                  max-width="374"
              >
                  <v-img
                      height="200"
                      src="/img/avbox-inst.png"
                  ></v-img>

                  <v-card-title class="inst-title">
                      <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Aloe Vera Box</p>
                  </v-card-title>

                  <v-card-actions class="pt-0 pb-4">
                      <v-btn style="margin: auto" text rounded color="success" :to="{name: 'AvBoxInstrum'}">Перейти</v-btn>
                  </v-card-actions>
              </v-card>
          </v-col>
            
            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/be-inst.png"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Бьюти эликсир</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'BeInstrum'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/gifts-inst.png"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Подарки</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'GiftsInstrum'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>            
  
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
              <v-card
                  class="mx-auto"
                  max-width="374"
              >
                  <v-img
                      height="200"
                      src="/img/learn/learning.png"
                  ></v-img>

                  <v-card-title class="inst-title">
                      <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Рекомендации по заболеваниям</p>
                  </v-card-title>

                  <v-card-actions class="pt-0 pb-4">
                      <v-btn style="margin: auto" text rounded color="success" :to="{name: 'ReqHealthLearn'}">Перейти</v-btn>
                  </v-card-actions>
              </v-card>
          </v-col>  
          <v-col
              cols="12"
              lg="3"
              md="4"
              sm="5"
          >
              <v-card
                  class="mx-auto"
                  max-width="374"
              >
                  <v-img
                      height="200"
                      src="/img/aroma-inst.png"
                  ></v-img>

                  <v-card-title class="inst-title">
                      <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Soul of Nature</p>
                  </v-card-title>

                  <v-card-actions class="pt-0 pb-4">
                      <v-btn style="margin: auto" text rounded color="success" :to="{name: 'AromaInstrum'}">Перейти</v-btn>
                  </v-card-actions>
              </v-card>
          </v-col>   
            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-card
                    class="mx-auto"
                    max-width="374"
                >
                    <v-img
                        height="200"
                        src="/img/cell/cell.jpg"
                    ></v-img>

                    <v-card-title class="inst-title">
                        <p class="mt-5" style="text-align: center; width: 100%; text-transform: uppercase">Суперфуды</p>
                    </v-card-title>

                    <v-card-actions class="pt-0 pb-4">
                        <v-btn style="margin: auto" text rounded color="success" :to="{name: 'CellInstrum'}">Перейти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!-- <v-dialog
            v-model="dialog"
            width="600px"
            v-if="paytimeUser && paytimeUser <= datePay"
        >
            <v-card>
                <v-card-title>
                    <span class="headline">Заканчивается срок доступа</span>
                </v-card-title>
                <v-card-text>
                    <p><strong><span style="color: #8c0000">{{ paytimeUser }}</span></strong> истекает доступ к инструментам</p>
                    <p><strong>ВАЖНО!</strong> Если вы не будете продлевать срок действия инструмента, то удалите вашу реферальную ссылку везде, где вы ее размещали.</p>
                    <p><strong>По истечении срока доступа новые заявки поступать не будут.</strong></p>
                    <p>При этом раздел "Мои заявки" для вас останется открытым и вы сможете продолжать работать с текущими заявками.</p>
                </v-card-text>

                <v-divider/>

                <v-card-text>
                    <p class="text-center mt-5 mb-2">
                        <v-btn color="success" :to="{name: 'PayInstruments'}">Доступ к инструментам</v-btn>
                    </p>                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialog = false"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
        <v-dialog
            v-model="dialogMessage"
            persistent
            width="600px"
        >
            <v-card>
                <!-- <v-card-title>
                    <span class="headline">Новости!</span>
                </v-card-title> -->
                <v-card-text class="pt-5">
                  <div class="mb-5">
                    <span v-html="notificationData.text"></span>
                  </div>
                </v-card-text>
                <v-divider/>
                <!--<v-card-text>
                    <p class="text-center mt-5 mb-2">
                        <v-btn color="success" @click="messagePay">Доступ к инструментам</v-btn>
                    </p>                   
                </v-card-text> -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="messageClose"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                color="blue"
            ></v-progress-circular>
        </v-overlay>
        <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="toTop"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import * as userService from '../../../services/user_service'

export default {
    name: "Instruments",
    data: () => ({
        overlay: false,
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: '',
        dialogMessage: false,
        newlvl_myprospects_message: 0,
        fab: false
    }),
    computed: {
        ...mapGetters({
            profile: 'user/profile',
            packageAll: 'user/getProfileAll',
            notification: 'notification/notification',
        }),
        notificationData() {
          return this.notification
        },
        profileData() {
            return this.profile
        },
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        },
    },
    created () {
      this.initializeNotification()
      this.messageCheck()
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageAll: 'user/loadProfileAll',
            addNotification: 'application/addNotification',
            loadProfilePackage: 'user/loadProfilePackage',
            loadNotification: 'notification/loadNotification',
        }),
        openDemo() {
            this.overlay = true
            userService.updateDemoAll()
                .then((response) => {                    
                    if (response.data && response.data.success) {
                        this.errors = {}
                        this.overlay = false
                        this.addNotification({
                            show: true,
                            text: response.data.message,
                            color: 'success'
                        })
                    }
                    location.reload();
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 500:
                            this.overlay = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        default:
                            this.overlay = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                    }
                })
        },
        initializeNotification () {
          this.loadNotification()
        },
        messageClose () {
          localStorage.setItem("newlvl_myprospects_message", this.notificationData.version)
          this.dialogMessage = false
        },
        messagePay () {
          localStorage.setItem("newlvl_myprospects_message", this.notificationData.version)
          this.dialogMessage = false
          this.$router.push({name: 'PayInstruments'})
        },        
        messageCheck () {
          if (localStorage.getItem("newlvl_myprospects_message")) {
            this.newlvl_myprospects_message = localStorage.getItem("newlvl_myprospects_message")
          }
        },
        scrollToBusiness() {
            let scrollBottom = document.getElementById("business")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        scrollToProducts() {
            let scrollBottom = document.getElementById("products")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        scrollToLearn() {
            let scrollBottom = document.getElementById("learn")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        onScroll (e) {
          if (typeof window === 'undefined') return
          const top = window.pageYOffset ||   e.target.scrollTop || 0
          this.fab = top > 20
        },
        toTop () {
          this.$vuetify.goTo(0)
        },
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime 
                    if (this.newlvl_myprospects_message != this.notificationData.version) {
                      this.dialogMessage = true
                    }
                })
        },        
    },
    mounted() {
        this.loadPackageAll()
        this.loadProfilePackage()
    },
}
</script>

<style scoped>
@font-face {
  font-family: "Intro";
  src: url('/fonts/IntroDemo-BlackCAPS.otf');
}
.inst-title {
  font-family: "Intro";
}
.block-nav {
  margin-top: 40px;
  text-align: center;
}
.p-nav {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.p-nav a {  
  text-decoration: underline;
}
</style>